<template>
  <v-card :key="modalKey">
    <v-toolbar class="mb-2" color="primary" dark>
      <v-toolbar-title class="text-h5" elevation="20">
      <span class="headline">Demande de développement</span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn icon @click="closeModal">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-toolbar>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col>
            <v-text-field 
              v-model="libelle" 
              label="libellé*" 
              :rules="[libelleRule]"
            ></v-text-field> 
          </v-col>
          <v-col>  
            <v-select
              :items="cards"
              :item-text="item => `${item.carte.libelle}`" 
              multiple
              label="Lier à une ou plusieurs cartes existantes"
              v-model="selectedCard"
            >
            </v-select>
          </v-col>

        </v-row>
        <v-row> 
          <v-col>
            <v-autocomplete
              ref="userSelect"
              :items="users"
              :item-text="item => `${item.firstname} ${item.lastname}`"
              :label="'Demandeur*'"
              v-model="usersSelected"
              :rules="[userSelectRule]"
            >
              <template v-slot:selection="{ item }">
                {{ item.firstname + ' ' + item.lastname }}
              </template>
              <template v-slot:item="{ item }">
                {{ item.firstname + ' ' + item.lastname }}
              </template>
            </v-autocomplete> 
          </v-col>
          <v-col>
            <v-select
              :items="originType"
              :label="'Origine*'"
              :rules="[originTypeRule]"
              v-model="selectedOriginType"
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center">
          <v-col>
            <v-select
              :items="devType"
              :label="'Type de développement*'"
              :rules="[devTypeRule]"
              v-model="selectedDevType"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              :items="impactType"
              :label="'Type de demande*'"
              :rules="[impactTypeRule]"
              v-model="selectedImpactType"
            ></v-select>
          </v-col> 
          <v-menu v-if="selectedImpactType === 'Urgent'" v-model="showMenu" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                :disabled="selectedImpactType !== 'Urgent'"
              >
                <v-icon color="red">mdi-calendar</v-icon>
              </v-btn>
            </template> 
            <v-date-picker
              v-model="urgentRequestDate"
              :locale="$i18n.locale"
              color="primary"
            ></v-date-picker> 
          </v-menu>
          <div v-if="selectedImpactType === 'Urgent'" style="width: 10rem;"> 
            <v-text-field
              v-model="urgentRequestDate"
              label="Date requise"
              readonly
            ></v-text-field>
          </div> 
        </v-row>
        <v-row> 
          <v-col>
            <v-autocomplete
              :items="dataModule" 
              :item-text="item => `${item.module}`"
              :label="'Module'"
              multiple
              v-model="module"
            >
              <template v-slot:selection="{ item }">
                {{ item.module }}
              </template> 
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              :items="dataSousModule" 
              :item-text="item => `${item.sous_module}`"
              :label="'Sous-module'"
              multiple
              v-model="sousModule"
            >
              <template v-slot:selection="{ item }">
                {{ item.sous_module }}
              </template> 
            </v-autocomplete>
          </v-col>
        </v-row> 
        <v-row>
        <!-- si piece jointe ajouteé depuis la modal afficher ici dans un carouselo de vuetify-->
          <v-col cols="12" v-if="newLogoPreviews.length > 0">
            <v-carousel 
              cycle
              hide-delimiters
              height="200"
            >
              <v-carousel-item v-for="(preview, index) in newLogoPreviews" :key="index">
                <v-img
                  :src="preview" 
                  contain
                  max-width="100%"
                  max-height="200"
                ></v-img>
              </v-carousel-item>
            </v-carousel>
          </v-col>
          <v-col>
            <editor 
              v-model="description"
              placeholder="Description de la demande*"
              :rules="[descriptionRule]"
              @select-attachment="dialogPieceJointe = true"
            ></editor>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="closeModal">Annuler</v-btn>
      <v-btn color="success" text @click="saveDevRequest" :disabled="!isValidate">Créer</v-btn>
    </v-card-actions>
    <v-dialog v-model="dialogPieceJointe" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Ajouter une pièce jointe</span>
        </v-card-title>
        <v-card-text>
          <v-container class="d-flex flex-column">
            <v-row>
              <v-col>
                <v-file-input
                  v-model="files"
                  label="Choisir un fichier"
                  @change="handleFilesChange"
                  multiple
                  accept="image/*, application/pdf"
                  type="file"
                  show-size
                  counter
                  clearable
                  name="uploadedfiles"
                  id="uploadedfiles"
                >
                <template #selection="{ index, text }">
                  <v-chip small label close color="primary" @click:close="deleteChip(index, text)">{{ text }}
                  </v-chip>
                </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row v-if="newLogoPreviews.length > 0">
              <v-col v-for="(preview, index) in newLogoPreviews" :key="index"
                style="overflow-x: auto;"
              >
                <v-img
                  :src="preview"
                  contain
                  max-width="100%"
                  max-height="300"
                ></v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogPieceJointe = false">Annuler</v-btn>
          <v-btn color="success" text @click="dialogPieceJointe = false">Ajouter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card> 
</template>

<script>

import UserService from '@/Services/UserService';
import * as Vuex from "vuex";
import ModuleService from "@/Services/SupportVision/ModuleService";
import DeveloppementService from '@/Services/SupportVision/DeveloppementService';
import Images from '@/Utils/Images';
import moment from 'moment'; 

export default {
  name: "createModule",
  components: {
    ClientPickerSelect: () => import("@/Components/Commons/Inputs/Client/ClientPickerSelect"),
    Editor: () => import('@/Components/Commons/Inputs/Editor/Editor.vue')
  },
  props: {
    placeholder: String,
  },
  data() {
    return { 
      showMenu: false,
      urgentRequestDate: null,
      dialogPieceJointe: false,
      libelle: '',
      dataModule: [],
      devType: ['Passerelle', 'Application', 'Interface/Module'],
      selectedDevType: null,
      impactType: ['Travaux unique', 'Release', 'Urgent'],
      selectedImpactType: null,
      originType: ['Client', 'Interne', 'Fiche liaison'],
      selectedOriginType: null,
      users: [],
      usersSelected: [],
      module: '',
      sousModule: '',
      description: '',
      files: [],
      newLogoPreviews: [],
      dataSousModule: [],
      modalKey: 0,
      libelleRule: (v) => !!v || 'Ce champ est requis',
      userSelectRule: (v) => v && v.length > 0 || 'Ce champ est requis',
      descriptionRule: (v) => !!v || 'Ce champ est requis',
      originTypeRule: (v) => v && v.length > 0 || 'Ce champ est requis',
      devTypeRule: (v) => v && v.length > 0 || 'Ce champ est requis',
      impactTypeRule: (v) => v && v.length > 0 || 'Ce champ est requis',
      dataFile: null,
      previousFiles: [],
      cards: [],  
      selectedCard: [], 
    }
  },  
  computed: {
    ...Vuex.mapState(["informations", "taskSupportVision"]),
    getStart() {
      return this.urgentRequestDate
          ? moment(this.urgentRequestDate, 'YYYY-MM-DD').format(this.getLocaleFormat(this.$i18n.locale))
          : ''
    },
    isValidate() {
      return this.libelle && this.usersSelected.length > 0 && this.description && this.selectedImpactType && this.selectedOriginType && this.selectedDevType;
    },
    userConnected() {
      return this.informations.firstname + ' ' + this.informations.lastname;
    }
  },
  // watch le user par defaut si modalkey change
  watch: {
    modalKey() {
      this.usersSelected = this.userConnected;
    }
  },
  methods: {
    reset() {
      // Reset all data fields
      this.libelle = '';
      this.usersSelected = [];
      this.module = '';
      this.sousModule = '';
      this.description = '';
      this.files = [];
      this.newLogoPreviews = []; // Clear all previews
      this.modalKey += 1;
      this.dataFile = null;
      this.previousFiles = [];

      // Reset validation rules
      this.userSelectRule = (v) => v && v.length > 0 || 'Ce champ est requis';
      this.descriptionRule = (v) => !!v || 'Ce champ est requis';
      this.libelleRule = (v) => !!v || 'Ce champ est requis';
      this.devTypeRule = (v) => v && v.length > 0 || 'Ce champ est requis';
      this.impactTypeRule = (v) => v && v.length > 0 || 'Ce champ est requis';
      this.originTypeRule = (v) => v && v.length > 0 || 'Ce champ est requis';

      // Reset selected values
      this.selectedImpactType = null;
      this.showMenu = false;
      this.urgentRequestDate = null;
      this.selectedDevType = null;
      this.selectedOriginType = null;
      this.selectedCard = [];
    },
    closeModal() {
      this.reset();
      this.$emit('close-create');
    }, 
    async handleFilesChange() {
      this.files = []
      const uploadedFiles = document.getElementById('uploadedfiles').files
      for (let i = 0; i < uploadedFiles.length; i++) {
        if (
          this.previousFiles !== undefined &&
          this.previousFiles !== null &&
          this.previousFiles.length <= 0
        ) {
          this.previousFiles.push(uploadedFiles[i])
        } else {
          const index = this.previousFiles.findIndex((x) => x.name === uploadedFiles[i].name)
          if (index >= 0) {
            this.previousFiles.splice(index, 1)
          }
          this.previousFiles.push(uploadedFiles[i])
        }
      }
      this.files = this.previousFiles

      this.newLogoPreviews = [];
      for (let file of this.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.newLogoPreviews.push(e.target.result);
        };
        reader.readAsDataURL(file);
      }
      this.dataFile = this.files;
    },
    deleteChip(index, text) {
      // Prompt here with text if required
      this.previousFiles.splice(index, 1)
      this.files = this.previousFiles
    },
    async saveDevRequest() { 
      const formData = new FormData();
      formData.append('userCreate', JSON.stringify(this.usersSelected));
      formData.append('module', this.module);
      formData.append('sous_module', this.sousModule);
      formData.append('libelle', this.libelle);
      formData.append('description', this.description);
      formData.append('originType', this.selectedOriginType);
      formData.append('devType', this.selectedDevType);
      formData.append('impactType', this.selectedImpactType);
      formData.append('urgentRequestDate', this.urgentRequestDate);

      // Ajouter la carte sélectionnée
      formData.append('linkedCard', this.selectedCard);

      // si des fichiers sont ajoutés
      this.dataFile && this.dataFile.forEach((file) => {
        formData.append('file', file);
      });
      
      await DeveloppementService.saveDev(formData);
      this.$emit('refresh');
      this.reset();
      this.closeModal();
    },
    async loadData() {
      const users = await UserService.getUsers().then((res) => {
        this.users = res;
      }); 
      this.usersSelected = this.userConnected

      const dataModule = await ModuleService.getModules();
      this.dataModule = dataModule.data.map((module) => module.module);
      const dataSousModule = await ModuleService.getSousModules();
      this.dataSousModule = dataSousModule;

      this.cards = this.$store.state.taskSupportVision;
    }
  },
  async mounted() { 
    await this.loadData();
  },
}
</script>
<style scoped>

</style>