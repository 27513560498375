var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{key:_vm.modalKey},[_c('v-toolbar',{staticClass:"mb-2",attrs:{"color":"primary","dark":""}},[_c('v-toolbar-title',{staticClass:"text-h5",attrs:{"elevation":"20"}},[_c('span',{staticClass:"headline"},[_vm._v("Demande de développement")])]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeModal}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"libellé*","rules":[_vm.libelleRule]},model:{value:(_vm.libelle),callback:function ($$v) {_vm.libelle=$$v},expression:"libelle"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.cards,"item-text":function (item) { return ("" + (item.carte.libelle)); },"multiple":"","label":"Lier à une ou plusieurs cartes existantes"},model:{value:(_vm.selectedCard),callback:function ($$v) {_vm.selectedCard=$$v},expression:"selectedCard"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{ref:"userSelect",attrs:{"items":_vm.users,"item-text":function (item) { return ((item.firstname) + " " + (item.lastname)); },"label":'Demandeur*',"rules":[_vm.userSelectRule]},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstname + ' ' + item.lastname)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstname + ' ' + item.lastname)+" ")]}}]),model:{value:(_vm.usersSelected),callback:function ($$v) {_vm.usersSelected=$$v},expression:"usersSelected"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.originType,"label":'Origine*',"rules":[_vm.originTypeRule]},model:{value:(_vm.selectedOriginType),callback:function ($$v) {_vm.selectedOriginType=$$v},expression:"selectedOriginType"}})],1)],1),_c('v-row',{staticClass:"d-flex align-center"},[_c('v-col',[_c('v-select',{attrs:{"items":_vm.devType,"label":'Type de développement*',"rules":[_vm.devTypeRule]},model:{value:(_vm.selectedDevType),callback:function ($$v) {_vm.selectedDevType=$$v},expression:"selectedDevType"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.impactType,"label":'Type de demande*',"rules":[_vm.impactTypeRule]},model:{value:(_vm.selectedImpactType),callback:function ($$v) {_vm.selectedImpactType=$$v},expression:"selectedImpactType"}})],1),(_vm.selectedImpactType === 'Urgent')?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.selectedImpactType !== 'Urgent'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-calendar")])],1)]}}],null,false,275545150),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-date-picker',{attrs:{"locale":_vm.$i18n.locale,"color":"primary"},model:{value:(_vm.urgentRequestDate),callback:function ($$v) {_vm.urgentRequestDate=$$v},expression:"urgentRequestDate"}})],1):_vm._e(),(_vm.selectedImpactType === 'Urgent')?_c('div',{staticStyle:{"width":"10rem"}},[_c('v-text-field',{attrs:{"label":"Date requise","readonly":""},model:{value:(_vm.urgentRequestDate),callback:function ($$v) {_vm.urgentRequestDate=$$v},expression:"urgentRequestDate"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.dataModule,"item-text":function (item) { return ("" + (item.module)); },"label":'Module',"multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.module)+" ")]}}]),model:{value:(_vm.module),callback:function ($$v) {_vm.module=$$v},expression:"module"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.dataSousModule,"item-text":function (item) { return ("" + (item.sous_module)); },"label":'Sous-module',"multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sous_module)+" ")]}}]),model:{value:(_vm.sousModule),callback:function ($$v) {_vm.sousModule=$$v},expression:"sousModule"}})],1)],1),_c('v-row',[(_vm.newLogoPreviews.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-carousel',{attrs:{"cycle":"","hide-delimiters":"","height":"200"}},_vm._l((_vm.newLogoPreviews),function(preview,index){return _c('v-carousel-item',{key:index},[_c('v-img',{attrs:{"src":preview,"contain":"","max-width":"100%","max-height":"200"}})],1)}),1)],1):_vm._e(),_c('v-col',[_c('editor',{attrs:{"placeholder":"Description de la demande*","rules":[_vm.descriptionRule]},on:{"select-attachment":function($event){_vm.dialogPieceJointe = true}},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeModal}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"color":"success","text":"","disabled":!_vm.isValidate},on:{"click":_vm.saveDevRequest}},[_vm._v("Créer")])],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogPieceJointe),callback:function ($$v) {_vm.dialogPieceJointe=$$v},expression:"dialogPieceJointe"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Ajouter une pièce jointe")])]),_c('v-card-text',[_c('v-container',{staticClass:"d-flex flex-column"},[_c('v-row',[_c('v-col',[_c('v-file-input',{attrs:{"label":"Choisir un fichier","multiple":"","accept":"image/*, application/pdf","type":"file","show-size":"","counter":"","clearable":"","name":"uploadedfiles","id":"uploadedfiles"},on:{"change":_vm.handleFilesChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","close":"","color":"primary"},on:{"click:close":function($event){return _vm.deleteChip(index, text)}}},[_vm._v(_vm._s(text)+" ")])]}}]),model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1)],1),(_vm.newLogoPreviews.length > 0)?_c('v-row',_vm._l((_vm.newLogoPreviews),function(preview,index){return _c('v-col',{key:index,staticStyle:{"overflow-x":"auto"}},[_c('v-img',{attrs:{"src":preview,"contain":"","max-width":"100%","max-height":"300"}})],1)}),1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogPieceJointe = false}}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":function($event){_vm.dialogPieceJointe = false}}},[_vm._v("Ajouter")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }